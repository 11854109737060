import { render, staticRenderFns } from "./specs_tempalte.vue?vue&type=template&id=7036fcc6&scoped=true"
import script from "./specs_tempalte.vue?vue&type=script&lang=js"
export * from "./specs_tempalte.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7036fcc6",
  null
  
)

export default component.exports
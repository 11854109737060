<template>
  <div class="main-box">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        row-key="id"
        style="width: 100%;"
        size="mini"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="名称">
      </el-table-column>
      <el-table-column
          label="内容">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.content" :key="index">
            <span>{{item.title}}：</span>
            <span v-for="(child,cindex) in item.txt" :key="cindex"><span v-if="cindex>0">、</span>{{child}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMenu(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="模板名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item label="" :label-width="formLabelWidth">
          <div v-if="form.content && form.content.length > 0">
            <div v-for="(item,index) in form.content" :key="index">
              <div class="mg-bt-5">
                <el-tag closable effect="dark" size="medium" @close="deleteMain(index)">{{ item.title }}</el-tag>
              </div>
              <el-tag class="mg-rt-10 mg-bt-5 fl" closable v-for="(child,index2) in item.txt" :key="index2" @close="deleteChild(index,index2)">{{ child }}</el-tag>
              <span style="width: 200px; float: left;">
              <el-input placeholder="请输入属性名称" class="font12" v-model="item.word">
                <el-button type="primary" slot="append" class="iv-search" @click="saveChild(index)">添 加</el-button>
              </el-input>
              </span>
              <div class="clr mg-bt-10"></div>
            </div>
          </div>
          <el-button type="primary" size="small" icon="el-icon-plus" @click="showMenu()" v-if="!showInput && form.content.length < 3">添加规格</el-button>
        </el-form-item>
        <el-form-item label="规格名称" :label-width="formLabelWidth" v-if="showInput">
          <el-row>
            <el-col :span="6"><el-input v-model="specsInfo.title" autocomplete="off" placeholder="请输入规格名"></el-input></el-col>
            <el-col :span="3" style="text-align: right">规格值：</el-col>
            <el-col :span="6"><el-input v-model="specsInfo.value" autocomplete="off" placeholder="请输入规格值"></el-input></el-col>
            <el-col :span="8" style="text-align: right">
              <el-button type="primary" @click="saveMain" size="small">确 定</el-button>
              <el-button @click="showInput = false" size="small">取 消</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加规格模板',
      dialogFormVisible: false,
      form: {
        id: 0,
        pid: 0,
        title: '',
        ordering: '',
        content: []
      },
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      formLabelWidth: '80px',
      tableData: [],
      showInput: false,
      specsInfo: {
        title: '',
        value: ''
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    clrSpecs() {
      this.specsInfo = {title: '', value: ''}
    },
    showMenu() {
      this.showInput = true
    },
    saveMain() {
      if(this.specsInfo.title == '') {
        this.fail('请输入规格名')
      } else if(this.specsInfo.value == '') {
        this.fail('请输入属性值')
      } else {
        this.form.content.push({title: this.specsInfo.title, txt: [this.specsInfo.value]})
        this.clrSpecs()
        this.showInput = false
      }
    },
    saveChild(index) {
      if(!this.form.content[index].word) {
        this.fail('请输入属性名称')
      } else if(this.form.content[index].txt.indexOf(this.form.content[index].word) > -1) {
        this.fail('该属性已存在')
      } else {
        this.form.content[index].txt.push(this.form.content[index].word)
        this.form.content[index].word = ''
      }
    },
    deleteMain(index) {
      this.form.content.splice(index,1)
    },
    deleteChild(index,index2) {
      this.form.content[index].txt.splice(index2,1)
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.goods.specsTemplateIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      this.form = item
      this.dialogFormVisible = true
    },
    addMenu() {
      this.form.id = 0
      this.form.title = ''
      this.form.content = []
      this.dialogFormVisible = true
    },
    saveMenu() {
      var that = this
      var param = {
        id: that.form.id,
        title: that.form.title,
        content: that.form.content
      }
      if(this.form.id == 0) {
        this.$api.goods.specsTemplateAdd(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      } else {
        this.$api.goods.specsTemplateEdit(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      }
    },
    delMenu() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.goods.specsTemplateDel({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
